import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
import Language from "@material-ui/icons/Language";
import Build from "@material-ui/icons/Build";
import CloudDone from "@material-ui/icons/CloudDone";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import {Link} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function ServicesSection() {
    const classes = useStyles();

    const getServicesDescription = () => {
        return <div>
            <p className={classes.description}>Naast websites/software en reparaties ondersteunen wij u met allerhande
                andere diensten. <br/>Enkele voorbeelden: </p>
            <ul className={classes.list}>
                <li>Aankoop / configureren van nieuwe computer(s)</li>
                <li>Email adressen voor u of uw bedrijf</li>
                <li>Onderhoud van computers</li>
                <li>Backup van data</li>
            </ul>
        </div>
    }

    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Wat we doen</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <Link to="/software">
                            <InfoArea
                                title="Website / Software"
                                description="Wij creëren websites met look en feeling van het jaar 2021 die er even goed uitzien op je computer als op je smartphone en tablet."
                                icon={Language}
                                iconColor="primary"
                                vertical
                            />
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>

                        <Link to="/repairs">
                            <InfoArea
                                title="Reparaties"
                                description="Is je computer of laptop stuk? Breng hem dan bij ons binnen. Wij geven een gratis diagnose en prijsofferte."
                                icon={Build}
                                iconColor="success"
                                vertical
                            />
                        </Link>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>

                        <Link to="/services">
                            <InfoArea
                                title="Andere Diensten"
                                description={getServicesDescription()}
                                icon={CloudDone}
                                iconColor="danger"
                                vertical
                            />
                        </Link>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );


}
