import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ServicesSection from "./Sections/ServicesSection.js";
import TeamSection from "./Sections/TeamSection.js";
import ContactSection from "./Sections/ContactSection.js";
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import ReactGA from "react-ga";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const cookieConsentValue = getCookieConsentValue("consent");

    const enableGA = () => {
        ReactGA.initialize('UA-139200935-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if(cookieConsentValue) {
        enableGA();
    }

    return (
        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="Van Gils IT Solutions"
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("assets/img/logo-transparant.png")}/>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <ServicesSection/>
                    <TeamSection/>
                    <ContactSection/>
                </div>
            </div>
            <Footer/>
            <CookieConsent
                enableDeclineButton
                location="bottom"
                buttonText="Accepteer"
                declineButtonText="Niet toestaan"
                cookieName="consent"
                style={{background: "#2B373B"}}
                buttonStyle={{color: "white", fontSize: "13px", backgroundColor: 'green'}}
                declineButtonStyle={{fontSize: "13px"}}
                expires={150}
                onAccept={() => {
                    enableGA();
                }}
            >
                We gebruiken technologieën, zoals cookies, om uw surfervaring makkelijker te maken en om het verkeer op de website te analyseren.
            </CookieConsent>
        </div>
    );
}
