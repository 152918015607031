import React, {useRef, useState} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {TailSpin} from 'react-loader-spinner';

const useStyles = makeStyles(styles);

export default function ContactSection() {
    const classes = useStyles();
    const form = useRef(null);
    const recaptchaRef = React.createRef();
    const initialState = {
        name: "",
        email: "",
        phone: "",
        message: ""
    };
    const [user, setUser] = useState(initialState);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [formSubmitSuccessful, setFormSubmitSuccessful] = useState(false);


    const onChangeRecaptcha = value => {
        if (value && value.length > 0) {
            setError(undefined);
        }
    }
    const isRequiredFilledIn = user.name.length > 0 && user.email.length > 0 && user.message.length > 0;

    const onChangeField = () => {
        if (isRequiredFilledIn) {
            setError(undefined);
        }
    }

    const handleSubmit = event => {
        event.preventDefault()
        const recaptchaValue = recaptchaRef.current.getValue();

        if (recaptchaValue && recaptchaValue.length > 0) {
            if (!isRequiredFilledIn) {
                setError("Gelieve alle verplichte velden (aangeduid met *) in te vullen.");
            } else {
                setLoading(true);
                setError(undefined)
                recaptchaRef.current.reset();
                axios.post('https://www.vangils-it.be:9092/api/mail',
                    {
                        name: user.name,
                        email: user.email,
                        phoneNumber: user.phone,
                        message: user.message
                    }, {
                        headers: {
                            "Authorization": "Basic dmFuZ2lscy1pdDpWYW5HaWxzSVQxOTkz"
                        }
                    }).then(res => {
                    setLoading(false);
                    if (res.status === 200) {
                        setFormSubmitSuccessful(true)
                        setTimeout(() => setFormSubmitSuccessful(false), 2000);
                        setUser(initialState);
                    } else {
                        setError("Er ging iets mis bij het versturen. Probeer later opnieuw of stuur een email naar <a href=mailto:stef@vangils-it.be />")
                    }
                })
                    // Handle errors here however you like
                    .catch(err => {
                        console.error("Failed to send feedback. Error: ", err);
                        setError("Er ging iets mis bij het versturen. Probeer later opnieuw of stuur een email naar stef@vangils-it.be");
                        setLoading(false);
                    })

            }
        } else {
            setError("ReCAPTCHA niet correct.");
        }
    }

    return  (
        <div className={classes.section} id={"contact"}>
            <GridContainer justify="center">
                <GridItem cs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Contacteer ons</h2>
                    <p className={classes.description}>
                        Hofdreef 16 <br/>
                        2460 Tielen <br/>
                        <a href="tel:+32476415635" className={classes.descriptionLink}>+32 4 76 41 56 35</a>
                    </p>
                    <a href="mailto:stef@vangils-it.be"><h4 className={classes.subTitle}>stef@vangils-it.be</h4></a>
                    <h5 className={classes.orTitle}>of</h5>
                    {loading && <TailSpin  color="#00BFFF" height={80} width={80} wrapperStyle={{justifyContent: "center"}}/>}
                    <form ref={form} onSubmit={handleSubmit}>
                        <GridContainer justify="center">
                            {error && error.length > 0 && <GridItem xs={12} sm={12} md={12}>
                                <h5 className={classes.error}>{error}</h5>
                            </GridItem>}
                            {formSubmitSuccessful && <GridItem xs={12} sm={12} md={12}>
                                <h5 className={classes.successMessage}>Bedankt voor ons te contacteren. U hoort zo snel
                                    mogelijk van ons.</h5>
                            </GridItem>}
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText="Naam*"
                                    id="name"
                                    name="user[name]"
                                    disabled={loading}
                                    defaultValue={user.name}
                                    onChange={e => {
                                        setUser({...user, "name": e.target.value});
                                        onChangeField();
                                    }}
                                    required
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Email*"
                                    id="email"
                                    name="user[email]"
                                    disabled={loading}
                                    defaultValue={user.email}
                                    required
                                    onChange={e => {
                                        setUser({...user, "email": e.target.value});
                                        onChangeField();
                                    }}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Telefoonnummer"
                                    id="name"
                                    name="user[phone]"
                                    disabled={loading}
                                    defaultValue={user.phone}
                                    onChange={e => {
                                        setUser({...user, "phone": e.target.value});
                                        onChangeField();
                                    }}
                                    required
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                            <CustomInput
                                labelText="Bericht*"
                                id="message"
                                name="user[message]"
                                disabled={loading}
                                defaultValue={user.message}
                                required
                                onChange={e => {
                                    setUser({...user, "message": e.target.value});
                                    onChangeField();
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.textArea
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5
                                }}
                            />
                            <GridItem xs={12} sm={12} md={12} className={classes.justifyCenter}>
                                {!loading && <div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6Le8vq0aAAAAAMxpWQOd8_U6IZT9eWwfXiYI8rzX"
                                        style={{justifyContent: "center", textAlign: "center", display: "inline-block"}}
                                        onChange={onChangeRecaptcha}
                                    />
                                </div>}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4} className={classes.justifyCenter}>
                                <Button type="submit" color="primary" disabled={loading}>Verzend bericht</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
