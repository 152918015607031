import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Devices from "@material-ui/icons/Devices";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Build from "@material-ui/icons/Build";
import Help from "@material-ui/icons/Help";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import Wifi from "@material-ui/icons/Wifi";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ServicesSection() {
    const classes = useStyles();

    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Diensten</h2>
                </GridItem>
            </GridContainer>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Aankoop & configuratie van computers"
                            description={"Wij nemen de last van het aankopen en opnieuw instellen van uw computer(s) uit uw handen. Samen kiezen we een computer op maat van uw voorkeur of stellen we de componenten samen. We zorgen ervoor dat de instellingen en applicaties klaar zijn om te gebruiken."}
                            icon={Devices}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Configuratie van diensten"
                            description="Wil u een email adres met je eigen domeinnaam of heb je hulp nodig bij het opzetten van bepaalde applicaties? Wij helpen je graag om dit in orde te brengen."
                            icon={AccountCircle}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Onderhoud"
                            description="Loopt je computer vaak vast of is hij traag, dan zorgen wij ervoor dat deze terug sneller wordt."
                            icon={Build}
                            iconColor="danger"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Backups"
                            description="Neem je niet regelmatig backups, dan heb je een grote kans dat je data verliest. Bijvoorbeeld: je verwijdert (per ongeluk) een bestand of je harde schijf crasht. Wij helpen bij het verzekeren dat u geen data verliest. Contacteer ons om samen te kijken welke oplossing het beste bij uw noden past."
                            icon={SettingsBackupRestore}
                            iconColor="primary"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Netwerk"
                            description="Doet uw Telenet of Proximus WiFi of netwerk het voor de zoveelste keer niet of ben je op zoek naar een veilig (bedrijfs)netwerk? We zoeken naar een oplossing op maat voor uw noden en budget."
                            icon={Wifi}
                            iconColor="success"
                            vertical
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <InfoArea
                            title="Overige"
                            description="Heeft u andere vragen dan kan u ons altijd contacteren via het contactformulier."
                            icon={Help}
                            iconColor="danger"
                            vertical
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );


}
