/*eslint-disable*/
import React, {useRef} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import {Link} from "react-router-dom";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {Apps, CloudDownload} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
    const classes = useStyles();
    const {close} = props;
    const executeScroll = () => {
        const titleElement = document.getElementById('contact');
        titleElement.scrollIntoView({behavior: 'smooth'})
        close();
    }

    return (
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Link to="/software" className={classes.listItemText} onClick={close}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                    >
                        Website / Software
                    </Button>
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/repairs" className={classes.listItemText} onClick={close}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                    >
                        Reparaties
                    </Button>
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/services" className={classes.listItemText} onClick={close}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                    >
                        Andere diensten
                    </Button>
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <a onClick={executeScroll} className={classes.listItemText}>
                    <Button
                        color="transparent"
                        className={classes.navLink}
                    >
                        Contact
                    </Button>
                </a>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="instagram-facebook"
                    title="Bezoek ons op Facebook"
                    placement={window.innerWidth > 959 ? "top" : "left"}
                    classes={{tooltip: classes.tooltip}}
                >
                    <Button
                        color="transparent"
                        href="https://www.facebook.com/Van-Gils-It-Solutions-393171597972978"
                        target="_blank"
                        className={classes.navLink}
                        onClick={close}
                    >
                        <i className={classes.socialIcons + " fab fa-facebook"}/>
                    </Button>
                </Tooltip>
            </ListItem>
        </List>
    );
}
