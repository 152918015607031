import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Route, Router, Switch, useLocation, withRouter} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import SoftwarePage from "./views/SoftwarePage/SoftwarePage";
import RepairsPage from "./views/RepairsPage/RepairsPage";
import ServicesPage from "./views/ServicesPage/ServicesPage";

var hist = createBrowserHistory();

function _ScrollToTop(props) {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)



ReactDOM.render(
    <Router history={hist}>
        <ScrollToTop>
            <Switch>
                <Route path="/services" component={ServicesPage}/>
                <Route path="/repairs" component={RepairsPage}/>
                <Route path="/software" component={SoftwarePage}/>
                <Route path="/" component={LandingPage}/>
                <Route path="/*" component={LandingPage}/>
            </Switch>
        </ScrollToTop>
    </Router>,
    document.getElementById("root")
);


