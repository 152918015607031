import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import iphone from "../../../assets/img/iphone-5.jpg";
import webdesign from "../../../assets/img/webdesign.jpg";

import styles from "assets/jss/material-kit-react/views/softwarePage";

const useStyles = makeStyles(styles);

export default function SoftwareSection() {
    const classes = useStyles();

    let smallScreen;
    if (window.innerWidth >= 768) {
        smallScreen = false;
    } else {
        smallScreen = true;
    }

    return (
        <div className={classes.section}>
            <div>
                <h2 className={classes.title}>
                    Mobiele applicaties
                </h2>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div>
                                <img className={classes.image} src={iphone} alt="iphone"/>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.descriptionContainer}>
                                <p className={classes.description}>
                                    Onze smartphone die we overal meedragen, staat vol met apps.
                                    Heb je een idee voor een app, dan ben je bij ons aan het juiste adres.
                                    Bij Van Gils IT Solutions hebben we de expertise in het creëren van apps voor zowel
                                    iOS als
                                    Android.
                                </p>
                                <br/>
                                <br/>

                                <p className={classes.description}>
                                    Neem gerust met ons contact op voor meer informatie.
                                </p>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>

            <div style={!smallScreen ? {marginTop: "60pt"} : null}>
                <h2 className={classes.title}>
                    Websites
                </h2>
                <div>
                    <GridContainer>
                        {smallScreen && <GridItem xs={12} sm={12} md={6}>
                            <div>
                                <img className={classes.image} src={webdesign} alt="webdesign"/>
                            </div>
                        </GridItem>}
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={smallScreen ? classes.descriptionContainer : null}>
                                <h3 className={classes.subTitle}>Websites Ontwerpen</h3>
                                <p className={classes.description}>
                                    Ben je op zoek naar een website voor je bedrijf, vereniging of event?
                                    We ontwerpen een website op maat van je vereisten en zoeken samen de gepaste
                                    oplossing.
                                    Contacteer ons voor meer informatie.
                                </p>
                                <br/>
                                <h3 className={classes.subTitle}>Web Apps</h3>
                                <p className={classes.description}>
                                    Moderne applicaties installeer je niet meer op je computer, maar gebruik je
                                    rechtstreeks in je
                                    favoriete browser.
                                    Bij Van Gils IT Solutions kan je terecht met al je ideeën voor zowel
                                    commerciële als interne applicaties.
                                </p>
                            </div>
                        </GridItem>
                        {!smallScreen && <GridItem xs={12} sm={12} md={6}>
                            <div>
                                <img className={classes.image} src={webdesign} alt="webdesign"/>
                            </div>
                        </GridItem>}
                    </GridContainer>
                </div>
            </div>

            <div style={!smallScreen ? {marginTop: "60pt"} : null}>
                <h2 className={classes.title}>
                    Software Ontwikkeling
                </h2>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div>
                                <img className={classes.image} src={webdesign} alt="webdesign"/>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <div className={classes.descriptionContainer}>
                                <p className={classes.description}>
                                    Als bedrijf heb je vaak gepersonaliseerde processen.
                                    De bestaande software pakketten geven je vaak niet de juiste en persoonlijke
                                    oplossing die je
                                    nodig hebt.
                                    Wij leveren software op maat van je bedrijf en je vereisten.
                                </p>
                                <br/>
                                <br/>

                                <p className={classes.description}>
                                    Neem gerust contact op en dan zoeken we samen naar een gepersonaliseerde oplossing.
                                </p>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );


}
