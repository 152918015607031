import {cardTitle, title} from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const repairsStyle = {
    section: {
        padding: "30px 0",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1em",
        minHeight: "32px",
    },
    subTitle: {
        marginTop: "0px",
        color: "#3C4858",
        textDecoration: "none",
        fontWeight: "700",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        marginBottom: "1em",
    },
    ...imagesStyle,
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardTitle,
    smallTitle: {
        color: "#6c757d"
    },
    descriptionContainer: {
        padding: "1em"
    },
    description: {
        color: "#6c757d",
        fontSize: "15pt"
    },
    justifyCenter: {
        justifyContent: "center !important"
    },
    image: {
        maxWidth: "100%",
        borderRadius: "20px"
    },
    container: {
        marginTop: "60pt"
    }
};

export default repairsStyle;
