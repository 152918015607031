import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import computer_repair from "../../../assets/img/computer_reparatie.jpg";

import styles from "assets/jss/material-kit-react/views/repairsPage";

const useStyles = makeStyles(styles);

export default function RepairsSection() {
    const classes = useStyles();

    return (
        <div className={classes.section}>
            <div>
                <h2 className={classes.title}>
                    Reparaties
                </h2>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <div>
                                <img className={classes.image} src={computer_repair} alt="computer_repair"/>
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <div>
                                <h3 className={classes.subTitle}>Gratis Diagnose</h3>
                                <p className={classes.description}>
                                    Bij de reparatie van een computer kunnen er allerlei kosten opduiken. Ik vind het
                                    belangrijk om
                                    hier
                                    transparant over te zijn.
                                    Daarom doen we een <strong>gratis diagnose</strong> van de problemen met de
                                    computer/laptop en
                                    maken we een gedetailleerde kostenraming.
                                    Zo is het voor u duidelijk hoeveel de herstelling zal kosten.
                                    <br/>
                                    <br/>
                                    Belangrijk om te weten is dat u na de diagnose niet verplicht bent om de
                                    computer door ons te
                                    laten herstellen. De keuze is aan u!
                                    <br/>
                                    <br/>
                                    U kan een afspraak maken door ons te bellen of te mailen. Onze
                                    contactgegevens kan u onderaan
                                    vinden.
                                </p>
                                <br/>
                                <h3 className={classes.subTitle}>Reparaties en Installaties aan huis</h3>
                                <p className={classes.description}>
                                    Indien gewenst, is er de mogelijkheid om reparaties en installaties op
                                    locatie uit te voeren.
                                </p>
                                <br/>
                                <br/>
                                <p className={classes.description}>
                                    Aarzel niet om met ons contact op te nemen.
                                    <br/>

                                </p>
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );


}
