import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import stef from "assets/img/faces/stef.png";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <div>
        <GridContainer className={classes.justifyCenter}>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={stef} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Stef Van Gils
                <br />
                <small className={classes.smallTitle}>Zaakvoerder</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Na een master Burgerlijk Ingenieur: Computerwetenschappen en een aantal jaar ervaring in het ontwikkelen van software,
                  besliste ik om Van Gils IT solutions op te richten in 2019.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
