import { title } from "assets/jss/material-kit-react.js";

const workStyle = {
  section: {
    padding: "70px 0"
  },
  title: {
    ...title,
    marginBottom: "20px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#5b5555",
    textAlign: "center",
    fontSize: "13pt",

    fontWeight: "500",
  },
  descriptionLink : {
    color: "#5b5555",
    textAlign: "center",
    fontSize: "13pt",
    fontWeight: "500",
    "&:hover,&:focus": {
      color: "#b6babe",
    }
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  justifyCenter: {
    justifyContent: "center !important",
    textAlign: "center !important",
    marginTop: "20pt"
  },
  subTitle: {
    marginTop: "0px",
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginBottom: "1em",
    textAlign: "center",
    "&:hover,&:focus": {
      color: "#b6babe",
    }
  },
  orTitle: {
    marginTop: "0px",
    color: "#3C4858",
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginBottom: "1em",
    textAlign: "center"
  },
  error: {
    backgroundColor: "red",
    borderRadius: "20px",
    padding: "10pt",
    textAlign: "center"
  },
  successMessage: {
    backgroundColor: "green",
    borderRadius: "20px",
    padding: "10pt",
    textAlign: "center"
  },
};

export default workStyle;
